<template>
  <div class="customer-card height">
    <!-- Datos del Cliente -->
    <b-card class="">
      <b-card-title class="mb-0">
        <h4>{{ cart.cliente.name+' '+cart.cliente.lastname }}</h4>
        <h6 v-if="contractBenefitUsed" class="mb-2"><feather-icon icon="UserIcon" size="14" /> {{ contractBenefitUsed }}</h6>
      </b-card-title>

      <b-row>
        <b-col cols="12" class="client-card d-flex justify-content-between margin-customer-card">
          <strong>Correo:</strong> {{ cart.cliente.email || 'N/A' }}
        </b-col>
        <b-col cols="12" class="client-card d-flex justify-content-between margin-customer-card">
          <strong>Teléfono:</strong> {{ cart.cliente.phone || 'N/A' }}
        </b-col>
        <b-col cols="12" class="client-card d-flex justify-content-between margin-customer-card">
          <strong>Hotel:</strong> {{ nameHotel || 'N/A' }}
        </b-col>
        <b-col cols="12" class="client-card d-flex justify-content-between margin-customer-card">
          <strong>Tipo cliente:</strong> {{ tipoCliente || 'N/A' }}
        </b-col>
        <b-col cols="12" v-if="cart.cliente.credit!=0" class="client-card d-flex justify-content-between margin-customer-card">
          <strong>Saldo a favor:</strong> {{ formatAmount(cart.cliente.credit || 0) }}
        </b-col>
        <b-col cols="12" v-if="cart.cliente.resortCredit!=0" class="client-card d-flex justify-content-between margin-customer-card">
          <strong>Resort Credit:</strong> {{ formatAmount(cart.cliente.resortCredit || 0) }}
        </b-col>
        <b-col cols="12" class="client-card d-flex justify-content-between margin-customer-card" v-if="cart.cliente.customeragent">
          <strong>Refiere:</strong> {{ cart.cliente.customeragent || 'N/A' }}
        </b-col>
      </b-row>
    </b-card>

    <!-- Totales -->
    <b-card class="">
      <b-card-title @click="collapseFileCard = !collapseFileCard" class="m-0">
        <h4>Totales</h4>
      </b-card-title>
      <b-row class="">
        <b-col cols="12" v-if="subTotalParcial != 0">
          <div class="client-card d-flex justify-content-between margin-customer-card">
            <span class="">Primer pago parcial:</span> {{ formatAmount(subTotalParcial) }}
          </div>
        </b-col>
        <b-col cols="12" v-if="subTotalParcial != 0">
          <div class="client-card d-flex justify-content-between margin-customer-card border-subtotals">
            <span class="">Otros cargos:</span> {{ formatAmount(subTotalParcialBooking) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div class="client-card d-flex justify-content-between margin-customer-card">
            <span class="">Subtotal:</span> {{ formatAmount(subTotal) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div class="client-card d-flex justify-content-between margin-customer-card">
            <span class="">Descuento:</span> {{ formatAmount(totalDiscount) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder">
            <span>Total:</span>USD {{ formatAmount(total) }}
          </div>
        </b-col>
      </b-row>

      <b-row class="" v-if="cart.cliente.currency.value != 1">
        <b-col cols="12">
          <div class="client-card d-flex justify-content-between margin-customer-card">
            <span class="">Tipo de cambio:</span> {{ formatAmount(currency.value) }}
          </div>
        </b-col>
        <b-col cols="12" v-if="currencySubTotalParcial != 0">
          <div class="client-card d-flex justify-content-between margin-customer-card" v-if="isReloadingProductsInCart">
            <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
          </div>
          <div class="client-card d-flex justify-content-between margin-customer-card" v-else>
            <span class="">Primer pago parcial:</span> {{ formatAmountGlobal(currencySubTotalParcial) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder pt-half" v-if="isReloadingProductsInCart">
            <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
          </div>
          <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder" v-else>
            <span>Total divisa:</span>{{currency.code}} {{ formatAmountGlobal(currencyTotal) }}
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Productos en el carrito que les falta completar campos -->
    <b-card class="" v-if="notReadyItems.length">
      <b-card-title @click="collapseUnreadyItemsCard = !collapseUnreadyItemsCard" class="m-0">
        <h4 class="d-flex justify-content-between" >Datos Faltantes ({{ notReadyItems.length }})<feather-icon :icon="collapseUnreadyItemsCard ? 'ChevronUpIcon' : 'ChevronDownIcon'" /></h4>
      </b-card-title>

      <b-collapse id="accordeon-items-card" v-model="collapseUnreadyItemsCard">
        <div class="mb-2">
          <b>Productos en el carrito que les falta completar campos: </b><hr>
          <table class="table table-sm table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Campo o acción faltante</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, indice) in notReadyItems" :key="item.key">
                <td scope="row"><b>{{indice + 1}}:</b>  {{ item.name }} </td>
                <td scope="row" style="color: red;">
                  <span v-for="(valor) in item.fields" :key="valor.key">
                    <li v-if="valor.isBenefit">Favor de aplicar la fecha de operacion para el beneficio</li>
                    <li v-if="!valor.campo.name">{{valor.campo}}</li>
                    <b-badge class="badgemargin" :id="`tooltip${valor.key}`" variant="danger" v-if="valor.campo.name">{{valor.campo.name}}</b-badge>
                    <b-tooltip :target="`tooltip${valor.key}`" triggers="hover">
                      <span v-for="(campos, index) in valor.campo.campos" :key="index" >
                        <li class="align-left" v-if="campos">{{campos}}</li>
                      </span>
                    </b-tooltip>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </b-card>

    <!-- Archivos en el Carrito -->
    <b-card class="" v-if="cart.files">
      <b-card-title @click="collapseFileCard = !collapseFileCard" class="m-0">
        <h4 class="d-flex justify-content-between" >Archivos ({{ cart.files.filedata.length }}) <feather-icon :icon="collapseFileCard ? 'ChevronUpIcon' : 'ChevronDownIcon'" /></h4>
      </b-card-title>

      <b-collapse id="accordeon-files-card" v-model="collapseFileCard">
        <div class="mt-2">
          <ul>
            <li class="client-card" v-for="(item, index) in cart.files.filedata" :key="index">
              <b-link :href="item.url" target="_blank">{{ item.name }}</b-link><br>
            </li>
          </ul>

          <strong>Notas:</strong> <br>{{cart.files.notes}}
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { itemsCartNotReady }from '@/helpers/shopHelper'
import { globalCurrencyFormat, toCurrency }from '@/helpers/helpers'
import { utils } from '@/modules/fivesClub/mixins/utils'

export default {
  mixins: [utils],
  props: {
    cart: {
      type: Object,
      required: true,
    },
    nameHotel:{
      type: String,
      required: true,
    },
    tipoCliente:{
      type: String,
      required: true,
    }
  },
  data(){
    return{
      collapseFileCard: false,
      collapseUnreadyItemsCard: false,
    }
  },
  computed: {
    ...mapState("shop", ["currency" ,"isReloadingProductsInCart"]),
    ...mapGetters('shop',['tc', 'subTotalParcial', 'subTotalParcialBooking', 'totalDiscount', 'subTotal', 'total', 'currencySubTotalParcial', 'currencySubTotalParcialBooking', 'currencyTotalDiscount', 'currencyTotal', 'currencySubTotal']),
    notReadyItems(){
      return itemsCartNotReady( this.cart.items )
    },
    contractBenefitUsed(){
      const contracts = this.cart?.contract
      if (contracts?.length) {
        const contract = contracts.find((contr) => contr.id === this.cart?.contractId)
        return contract ? `${contract?.resortsname || ''} | ${contract?.housingnumber || ''} | ${contract?.membershipcode || ''}` : null
      } else return null
    },
  },
  methods: {
    formatAmountGlobal(amount){
      return globalCurrencyFormat(amount)
    },
    formatAmount(amount){
      return toCurrency(amount)
    },
  }
}
</script>

<style lang="scss" scoped>

.badgemargin{
  margin: 3px 5px 3px 0;
}
.totalParcial{
color:crimson
}
.height{
  height: fit-content !important;
}

.client-card{
  margin: 0.1rem 0;
}
</style>